<template>
  <div class="question-box" v-loading="loading">
    <div class="question-box_header">
      <div>
        <div style="color: #E6A23C" v-if="tips">{{tips}}</div>
      </div>
      <div class="count-down-box">
        <CountDown ref="countDown" @timeOut="timeOut" />
        <el-button style="margin-left: 20px;" type="primary" size="small" @click="confirmSubmitPaper">交卷</el-button>
      </div>
    </div>
    <div class="question-content-box">
      <div class="question-list-box">
        <div class="question-item-box" :id="'option' + index" v-for="(item, index) in questionList" :key="index">
          <div class="item-tile">
            <div class="index">{{ index + 1 }}. </div>
            <div style="display: flex; align-items: baseline;">
              <el-tag size="mini" v-if="item.kind == 'SINGLE'">单选题</el-tag>
              <el-tag size="mini" v-else-if="item.kind == 'MULTI'">多选题</el-tag>
              <el-tag size="mini" v-else>判断题</el-tag>

              <div style="margin-left: 10px;">{{ item.title }}</div>
            </div>
          </div>
          <div class="options-list-box">
            <div class="option-item-box" v-for="(value, key) in item.options_list" :key="key" @click="checkedOption(item, key)">
              <div class="key" :class="isChecked(item, key) ? 'active' : ''">{{ key }}</div>
              <div class="value">{{ value }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 试卷底部导航 -->
      <div class="bottom-box">
        <div class="bottom-header-box" @click="showQuestionListFun">
          答题卡
          <i :class="showQuestionList ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" style="margin-left: 10px;"></i>
        </div>
        <div class="bottom-box_list" :style="{ 'max-height': showQuestionList ? '270px' : '0' }">
          <div class="bottom-question-list-box">
            <div class="question-box_item" :class="item.checkedOption.length > 0 ? 'active' : ''" v-for="(item, index) in questionList" :key="index" @click="goAnchor(index)">{{ index + 1 }}</div>
          </div>
        </div>
      </div>
    </div>
    <el-backtop target=".question-box"></el-backtop>
    <el-dialog title="考试结束" :visible.sync="showSubmit" width="50%" :close-on-click-modal="false" :show-close="false" center>
      <div class="submit-content-box">
        <div class="main-paper-name" style="margin-bottom: 30px;">
          <i class="el-icon-success" style="color: #67C23A; font-size: 26px;" v-if="submitInfo.is_pass == 1"></i>
          <i class="el-icon-error" style="color: #F56C6C; font-size: 26px;" type="primary" v-else></i>
          <div style="margin-left: 10px;">{{ submitInfo.is_pass == 1 ? '恭喜您，考试通过！' : '很遗憾，考试未通过！' }}</div>
        </div>
        <div class="main-paper-name">本次考试得分：{{ submitInfo.score }}分</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-if="submitInfo.can_bk" @click="startAgain">重新考试</el-button>
        <el-button type="primary" v-else @click="exitExam">退出考试</el-button>
      </span>
    </el-dialog>
    <CameraModalVue ref="cameraModalVue" @ocrSuccess="ocrSuccess" @handClose="handCloseOrc"></CameraModalVue>
  </div>
</template>

<script>
import { examGetQuestionList, examSubmit, commonConfig, examCacheExam } from '@/api/api-list'
import CountDown from '@/components/countDowm.vue'
import CameraModalVue from '@/components/CameraModal.vue'
import apiConfig from "../api/config.js";
export default {
  components: {
    CountDown,
    CameraModalVue
  },
  data() {
    return {
      examInfo: {},
      questionList: [],
      showQuestionList: true,
      startTime: 0,
      showSubmit: false,
      submitInfo: {},
      configInfo: {},
      examEnd: false,
      loading: true,
      tips: "",
      minSubmitTime: 0,
      is_bk: 0
    };
  },

  computed: {
    // 选中的选项
    isChecked() {
      return (item, key) => {
        for (let i = 0; i < item.checkedOption.length; i++) {
          if (item.checkedOption[i] == key) {
            return true
          }
        }
      }
    }
  },

  mounted() {
    this.examInfo = this.$route.query
    this.loading = true
    this.getQuestionList()
    this.getCommonConfig()
  },

  methods: {
    // 退出考试，关闭所有页面，跳到登录页
    exitExam() {
      localStorage.removeItem('userInfo')
      localStorage.removeItem('token')
      this.$router.push('/')
    },
    startAgain() {
      this.$router.go(-1)
    },
    // 获取配置信息
    async getCommonConfig(type) {
      // 考试中，看看几时开始人脸识别
      let res = await commonConfig()
      if (res.code == 1) {
        let configInfo = res.data
        if (configInfo.ocr.exam_process_ocr == 1) {
          if (type == 'handCloseOrc') {
            this.$refs.cameraModalVue.showOcrModal()
            return
          }
          let seconds = Number(configInfo.ocr.exam_process_ocr_minutes) * 60 * 1000
          // 考试期间需要人脸识别
          setTimeout(() => {
            // 暂停倒计时
            if (!this.examEnd) {
              this.pauseCountDown()
              this.$refs.cameraModalVue.showOcrModal()
            } else {
              console.log("考试结束")
            }
          }, seconds);
        }
      }
    },
    // 手动关闭识别弹窗
    handCloseOrc() {
      this.getCommonConfig('handCloseOrc')
    },
    // 暂停倒计时
    pauseCountDown() {
      console.log(this.$refs.countDown)
      this.$refs.countDown.stopCountDown()
    },
    // 识别成功继续考试
    ocrSuccess() {
      // 继续倒计时
      this.$refs.countDown.keepCountdown()
      // 重新获取配置信息，防止考试时长被修改
      this.getCommonConfig()
    },
    checkedOption(item, key) {
      if (item.kind == 'MULTI') {
        for (let i = 0; i < item.checkedOption.length; i++) {
          if (item.checkedOption[i] == key) {
            item.checkedOption.splice(i, 1)
            this.$forceUpdate()
            return
          }
        }
        item.checkedOption.push(key)
      } else {
        item.checkedOption = [key]
      }
      this.cacheExam()
      this.$forceUpdate()
    },

    // 缓存答题记录
    async cacheExam(){
      let questions = this.handleQuestions()
      let params = {
        number: this.examInfo.number,
        paper_id: this.examInfo.paper_id,
        start_time: this.startTime,
        is_bk: this.is_bk,
        seconds: this.$refs.countDown.getUsedTime(),
        questions: questions
      }
      await examCacheExam(params)
    },

    async getQuestionList() {
      let res = await examGetQuestionList({ 'number': this.examInfo.number })
      if (res.code == 1) {
        let seconds = res.data.seconds?res.data.seconds:res.data.paper.limit_time
        this.$refs.countDown.init(seconds)
        this.questionList = res.data.questions
        this.startTime = res.data.start_time
        this.tips = res.data.tips
        this.is_bk = res.data.is_bk?res.data.is_bk:0
        this.minSubmitTime = res.data.start_time*1000 + (res.data.paper.submit_limit_time * 60 * 1000)
        console.log('this.minSubmitTime',this.minSubmitTime)
        for (let i = 0; i < this.questionList.length; i++) {
          if(res.data.cache_questions && res.data.cache_questions.length && res.data.cache_questions[i].answer){
            this.questionList[i].checkedOption = res.data.cache_questions[i].answer.split(',')
          }else{
            this.questionList[i].checkedOption = []
          }
        }
        console.log('this.questionList', this.questionList)
      }
      this.loading = false
    },
    showQuestionListFun() {
      this.showQuestionList = !this.showQuestionList
    },
    //锚点跳转
    goAnchor(index) {
      document.querySelector(".question-box").scrollTop = this.$el.querySelector('#option' + index).offsetTop;
    },
    // 考试时间到
    timeOut() {
      this.$notify({
        title: '提示',
        message: '考试时间到，已自动交卷',
        type: 'warning'
      });
      this.submitPaper()
    },
    // 提交试卷
    confirmSubmitPaper() {
      let newDate = new Date().getTime()
      if (this.minSubmitTime > newDate) {
        this.$message({
          title: '提示',
          message: this.tips,
          type: 'warning'
        });
        return
      }
      let message = "确定提交试卷吗?"
      for (let i = 0; i < this.questionList.length; i++) {
        if (this.questionList[i].checkedOption.length == 0) {
          message = '你还有题目未作答，确定提交试卷吗?'
        }
      }
      this.$confirm(message, {
        title: '提示',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.submitPaper()
      }).catch(() => { })
    },
    
    async submitPaper() {
      this.loading = true
      let questions = this.handleQuestions()
      let params = {
        number: this.examInfo.number,
        paper_id: this.examInfo.paper_id,
        start_time: this.startTime,
        questions: questions
      }
      let res = await examSubmit(params)
      this.loading = false
      if (res.code == 1) {
        this.examEnd = true
        this.$refs.countDown.stopCountDown()
        this.$refs.cameraModalVue.stopGetCommonConfig()
        this.submitInfo = res.data
        console.log('交完卷后的信息', this.submitInfo)
        this.showSubmit = true
      }
    },

    handleQuestions(){
      let questions = []
      for (let i = 0; i < this.questionList.length; i++) {
        questions.push({
          id: this.questionList[i].id,
          answer: this.questionList[i].checkedOption.join(',')
        })
      }
      return questions
    },
  },
}
</script>
<style scoped lang='scss'>
.question-box {
  height: 100vh;
  overflow: scroll;
  margin: auto;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background-color: #f1f1f1;
  transform: all 0.5s;
  padding-top: 80px;

  .question-box_header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 60px;
    background-color: #ffffff;
    z-index: 99;
    box-shadow: 0px 0px 10px 0 #aaaaaa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;
    box-sizing: border-box;

    .count-down-box {
      display: flex;
      align-items: center;
    }
  }

  .question-content-box {
    width: 1024px;
    min-height: 100vh;
    position: relative;
    padding-bottom: 80px;

    .question-list-box {
      width: 100%;
      height: 100%;

      .question-item-box {
        background-color: #ffffff;
        padding: 40px;
        border-bottom: 1px solid #eeeeee;
        &:last-child {
          padding-bottom: 150px;
        }
        .item-tile {
          margin-bottom: 20px;
          line-height: 25px;
          display: flex;

          .index {
            margin-right: 10px;
          }
        }

        .options-list-box {
          margin-left: 30px;

          .option-item-box {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            cursor: pointer;

            .key {
              width: 35px;
              height: 35px;
              margin-right: 10px;
              border: 1px solid #dddddd;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;

              &.active {
                background-color: #409eff;
                color: #ffffff;
                border: 1px solid #409eff;
              }
            }
          }
        }
      }
    }

    .bottom-box {
      position: fixed;
      bottom: 0;
      width: 1024px;
      background: #ffffff;
      border: 1px solid #dddddd;
      box-sizing: border-box;

      .bottom-header-box {
        position: absolute;
        width: 120px;
        height: 40px;
        border-left: 1px solid #dddddd;
        border-top: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -40px;
        left: -1px;
        font-size: 14px;
        background: #ffffff;
        z-index: 9999;
        cursor: pointer;

        &:hover {
          color: #409eff;
        }
      }

      .bottom-box_list {
        padding: 18px 28px 0;
        transition: all 0.3s;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 0;
          /* 隐藏滚动条 */
        }

        &::-webkit-scrollbar-thumb {
          background-color: #f1f1f1;
          border-radius: 10px;
        }

        .bottom-question-list-box {
          height: 100%;
          margin-bottom: 30px;
          display: flex;
          flex-wrap: wrap;

          .question-box_item {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            padding: 0;
            width: 36px;
            height: 36px;
            font-size: 14px;
            border: 1px solid #dde0e5;
            color: #999fa4;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;

            &:hover {
              background: #409eff;
              border: 1px solid #409eff;
              color: #fff;
              border-radius: 8px;
            }

            &.active {
              border: 1px solid #409eff;
              color: #409eff;
              background: #ebf1fe;
            }
          }
        }
      }
    }
  }

  .submit-content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .main-paper-name {
      font-size: 20px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }

    .main-describe {
      text-indent: calc(2em + 5px);
      letter-spacing: 2px;
    }
  }
}
</style>