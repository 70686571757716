import Vue from 'vue'
import VueRouter from 'vue-router'
import loginView from '../views/login.vue'
import examListView from '../views/exam-list.vue'
import examView from '../views/exam.vue'
import certView from '../views/cert.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: loginView
  },
  {
    path: '/exam-list',
    name: 'exam-list',
    component: examListView
  },
  {
    path: '/exam',
    name: 'exam',
    component: examView
  },
  {
    path: '/cert',
    name: 'cert',
    component: certView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/h5/',
  routes
})

export default router
