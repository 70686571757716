<template>
  <div class="time">考试倒计时：{{ hr }}时{{ min }}分{{ sec }}秒</div>
</template>

<script>
export default {
  data() {
    return {
      content: '',
      endTime: 0,
      hr: 0,
      min: 0,
      sec: 0,
      isStop: false,
      saveEndTime: 0
    }
  },
  methods: {
    init(endTime) {
      this.endTime = endTime
      this.saveEndTime = endTime
      this.countdown()
    },
    stopCountDown(){
      this.isStop = true
    },
    // 继续倒计时
    keepCountdown(){
      this.isStop = false
      this.countdown()
    },
    getUsedTime(){
      return this.endTime
    },
    countdown() {
      if (this.endTime < 0 || this.isStop) return;
      let day = parseInt(this.endTime / 60 / 60 / 24)
      let hr = parseInt(this.endTime / 60 / 60 % 24)
      let min = parseInt(this.endTime / 60 % 60)
      let sec = parseInt(this.endTime % 60)
      this.day = day
      this.hr = hr > 9 ? hr : '0' + hr
      this.min = min > 9 ? min : '0' + min
      this.sec = sec > 9 ? sec : '0' + sec
      if (day > 0 || hr > 0 || min > 0 || sec > 0) {
        setTimeout(() => {
          this.endTime--
          this.countdown()
        }, 1000)
      } else {
        this.$emit('timeOut')
      }
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="scss" scoped>
  .time{
    color: #409eff;
    font-size: 14px;
  }
</style>