import axios from "./axios.js";
let {get, post } = axios
// 登录接口
export const userLogin = data => post("/api/user/login",data)
// 证书查询
export const userCert = data => post("/api/user/cert",data)
// 人像识别
export const userOcr = data => post("/api/user/ocr",data)
// 上传图片
export const commonUpload = data => post("/api/common/upload",data, 'form')
// 系统配置
export const commonConfig = data => post("/api/common/config",data)
// 考试列表
export const examGetList = data => post("/api/exam/getList",data)
// 获取试卷
export const examGetQuestionList = data => post("/api/exam/getQuestionList",data)
// 提交试卷
export const examSubmit = data => post("/api/exam/submit",data)
// 缓存答题记录
export const examCacheExam = data => post("/api/exam/cacheExam",data)



