import axios from "axios";
import apiConfig from "./config.js";
import qs from "qs";
import {
  Notification
} from 'element-ui'
axios.defaults.baseURL = apiConfig.host //正式

//post请求头
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";

// 表示跨域请求时是否需要使用凭证 允许跨域携带cookie信息
axios.defaults.withCredentials = false;

// 允许跨域
axios.defaults.headers.post["Access-Control-Allow-Origin-Type"] = "*";

//设置超时
axios.defaults.timeout = 15000;

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      // config.headers.Authorization = `Bearer ${token}`;
      config.headers.token = `${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    if (response.status == 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  }
);
export default {
  /**
   * @param {String} url 
   * @param {Object} data 
   * @returns Promise
   */
  post(url, data, contentType) {
    return new Promise((resolve, reject) => {
      if(contentType!='form'){
        data = qs.stringify(data)
      }
      axios({
        method: 'post',
        url,
        data: data,
        headers: {
          "Content-Type": contentType=='form'?"multipart/form-data":"application/x-www-form-urlencoded;charset=UTF-8"
        }
      })
        .then(res => {
          if (res.data.code == 0) {
            Notification({
              title: '错误',
              message: res.data.msg,
              type: 'error',
            })
          }
          resolve(res.data)
        })
        .catch(err => {
          let errData = err.response.data
          Notification({
            title: '错误',
            message: errData.msg,
            type: 'error',
            onClose:()=>{
              if(errData.code==401){
                localStorage.removeItem('token')
                window.location.href='/'
              }
            },
            onClick:()=>{
              if(errData.code==401){
                localStorage.removeItem('token')
                window.location.href='/'
              }
            }
          })
          resolve(err)
        });
    })
  },

  get(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url,
        params: data,
      })
        .then(res => {
          if (res.data.code == 0) {
            Notification({
              title: '错误',
              message: res.data.msg,
              type: 'error',
            })
          }
          resolve(res.data)
        })
        .catch(err => {
          Notification({
            title: '错误',
            message: JSON.stringify(err),
            type: 'error',
          })
          resolve(err)
        })
    })
  }
};