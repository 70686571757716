<template>
  <div class="login-box">
    <div class="top-bg-box"></div>
    <div class="container-box">
      <div class="title">考试系统</div>
      <div class="login-content-box">
        <!-- <div style="text-align: center; font-size: 24px">登录</div> -->
        <el-form v-loading="loginLoading" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
          <el-form-item style="margin-top:50px" label="身份证号码：" prop="account">
            <el-input class="input" v-model="ruleForm.account" placeholder="请输入您的账号"></el-input>
          </el-form-item>
          <el-form-item style="margin-top:30px" label="密码：" prop="password">
            <el-input class="input" placeholder="请输入您的密码" v-model="ruleForm.password" show-password></el-input>
          </el-form-item>
        </el-form>
        <div class="btn-box">
          <el-button type="primary" style="width:40%;height: 40px" size="medium" @click="login" :loading="loginLoading">登录</el-button>
          <!-- <el-button type="primary" style="width:40%;height: 40px" size="medium" @click="login" :loading="loginLoading">我要查证</el-button> -->
        </div>
        <!-- <div class="check-testify-box">
          <div class="check-testify-box_item" @click="gotoCertificate">
            我要查证<i class="el-icon-arrow-right"></i>
          </div>
        </div> -->
      </div>
    </div>
    <div class="bottom-bg-box"></div>
  </div>
</template>

<script>
import {
  userLogin,
  examGetList
} from '../api/api-list.js'
export default {
  data() {
    return {
      account: '',
      password: '',
      loginLoading: false,
      rules: {
        account: [
          { required: true, message: '请输入身份证号码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
      ruleForm: {
        account: '',
        password: ''
      },
    }
  },
  methods: {
    login() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submit()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    async submit() {
      this.loginLoading = true
      let params = {
        account: this.ruleForm.account,
        password: this.ruleForm.password
      }
      let res = await userLogin(params)
      console.log("res", res)
      this.loginLoading = false
      if (res.code == 1) {
        localStorage.setItem('userInfo', JSON.stringify(res.data.userinfo))
        localStorage.setItem('token', res.data.userinfo.token)
        // 登录成功后看看有无可以考试的列表
        this.getExamList()
      }
    },

     // 获取考试列表
     async getExamList() {
      let res = await examGetList()
      if (res.code == 1) {
        this.$notify({
          title: '成功',
          message: '登录成功',
          type: 'success',
          duration: 1000
        });
        setTimeout(() => {
          this.$router.push('/exam-list')
        }, 1000)
      }
    },

    gotoCertificate(){
      this.$router.push('/certificate')
    },
  }
}
</script>

<style lang="scss" scoped>
// PC端
.login-box {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: repeating-linear-gradient(0deg, #79bbff, #409eff);

  .top-bg-box {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 460px;
    background: url("../static/login/bg1.png") no-repeat;
    background-size: 100% 100%;
  }

  .container-box {
    width: 100vw;
    top: 20vh;
    position: absolute;
    z-index: 9999;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    z-index: 99;

    .title {
      font-size: 42px;
      font-weight: 700;
      color: #ffffff;
      display: flex;
      justify-content: center;
    }

    .login-content-box {
      width: 690px;
      background-color: #ffffff;
      border-radius: 30px;
      margin: 60px auto;
      padding: 30px;

      .login-item-box {
        display: flex;
        align-items: center;
      }

      .account {
        margin-top: 50px;
      }

      .password {
        margin-top: 30px;
      }

      .btn-box {
        margin-top: 50px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
      }

      .check-testify-box {
        text-align: right;

        .check-testify-box_item {
          display: inline-block;
          box-sizing: border-box;
          color: #409eff;
          cursor: pointer;
          border-bottom: 1px solid #FFFFFF;

          &:hover {
            color: #66b1ff;
            border-bottom: 1px solid #66b1ff;
          }
        }
      }
    }
  }

  .bottom-bg-box {
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 93px;
    background: url("../static/login/bg2.png") no-repeat;
    background-size: 100% 100%;
  }
}
</style>
