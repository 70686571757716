<template>
  <div class="login-box">
    <div class="top-bg-box"></div>
    <div class="container-box">
      <div class="title1">一般生产经营单位安全生产知识和管理能力考核合格信息查询平台</div>
      <div class="title" v-if="configInfo.system">{{ configInfo.system.cert_issue_name }}</div>

      <div class="login-content-box">
        <!-- <div class="tabs-box">
          <div class="tabs-box_item" @click="changeTabs(index)" :class="activeTabIndex == index ? 'active' : ''"
            v-for="(tab, index) in tabs" :key="index">{{ tab.name }}</div>
        </div> -->
        <div style="padding: 0 30px;">
          <!-- 证书号查询 -->
          <el-form v-loading="loginLoading" :model="ruleForm" ref="ruleForm" :rules="rules" class="demo-ruleForm">
            <el-form-item style="margin-top:50px" label="证件类型：" prop="type">
              <el-select style="width: 100%;" v-model="ruleForm.type" placeholder="请选择证件类型">
                <el-option label="身份证号" value="idcard"></el-option>
                <el-option label="证书号" value="number"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="margin-top:30px" label="证件号码：" prop="number">
              <el-input style="width: 100%;" class="input" v-model="ruleForm.number" placeholder="请输入证件号码"></el-input>
            </el-form-item>
            <el-form-item style="margin-top:30px; " label="图片验证码：" prop="verifyCode" class="verifyCodeItemCss">
              <div style="display: flex; width: 100%;align-items: center; line-height: 0;">
                <el-input style="" type="number" class="verify_css" placeholder="请输入4位验证码" v-model="ruleForm.verifyCode"></el-input>
                <!--关键 ↓-->
                <div style="margin-left: 10px;" id="v_container"></div>
              </div>
            </el-form-item>
          </el-form>
          <div class="btn-box">
            <el-button type="primary" style="width:30%;height: 40px" size="medium" @click="submitForm('ruleForm')" :loading="loginLoading">查询</el-button>
            <el-button style="width:30%;height: 40px" @click="resetForm('ruleForm')">重置</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-bg-box"></div>

    <el-dialog title="证书图片" width="80vw" :visible.sync="showImageDialog" center :modal-append-to-body="false">
      <div style="display: flex; flex-direction: column; align-items: center;">
        <el-image style="width: 100%; height: 100%" :src="imgSrc" :preview-src-list="[imgSrc]">
        </el-image>
      </div>
      <div style="text-align: center; margin-top: 30px;">
        <el-button type="primary" @click="clickDownImage(imgSrc, '证书图片')">下载图片</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  commonConfig,
  userCert
} from '../api/api-list.js'
import { GVerify } from '../static/verifyCode';
export default {
  data() {
    return {
      activeTabIndex: 0,
      tabs: [
        {
          name: '按身份证号查询',
          type: 'id'
        },
        {
          name: '按证书号查询',
          type: 'certificate'
        }
      ],
      loginLoading: false,
      ruleForm: {
        type: '',
        number: '',
        verifyCode: '',
      },
      rules: {
        type: [
          { required: true, message: '请选择证件类型', trigger: 'blur' }
        ],
        number: [{ required: true, message: '请输入证件号码', trigger: 'blur' }],
        verifyCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      configInfo: {},
      showImageDialog: false,
      imgSrc: '',
      verifyCodeObj: null,
      isMobile: false
    }
  },
  async mounted() {
    // window.document.title = '一般生产经营单位安全生产知识和管理能力考核合格信息查询平台'
    let options = { // 默认options参数值
      id: 'v_container', // 容器Id
      canvasId: 'verifyCanvas', // canvas的ID
      width: '160', // 默认canvas宽度
      height: '40', // 默认canvas高度
      type: 'number', // 图形验证码默认类型blend:数字字母混合类型、number:纯数字、letter:纯字母
      code: ''
    }
    if (this._isMobile()) {
      this.isMobile = true
      options.width = '80'
      this.verifyCodeObj = new GVerify(options)
    } else {
      this.verifyCodeObj = new GVerify(options)
    }


    console.log('this.isMobile', this.isMobile)
    let res = await commonConfig()
    if (res.code == 1) {
      this.configInfo = res.data
    }
  },
  methods: {
    clickDownImage(url, name) {
      let image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = url
      let _this = this
      image.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob((blob) => {
          let url = URL.createObjectURL(blob)
          _this.downloadImage(url, name)
          // 用完释放URL对象
          URL.revokeObjectURL(url)
        })
      }
    },
    downloadImage(href, name) {
      let eleLink = document.createElement('a')
      eleLink.download = name
      eleLink.href = href
      eleLink.click()
      eleLink.remove()
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    changeTabs(index) {
      this.activeTabIndex = index
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 获取验证码
          var verifyFlag = this.verifyCodeObj.validate(this.ruleForm.verifyCode)
          if (!verifyFlag) {
            this.$notify.error({
              title: '系统提示',
              message: '验证码输入错误'
            })
            return;
          }
          this.submit()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    async submit() {
      this.loginLoading = true
      let res = await userCert(this.ruleForm)
      console.log("res", res)
      this.loginLoading = false
      if (res.code == 1 && res.data.image) {
        this.imgSrc = res.data.image
        this.showImageDialog = true
      }
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .container-box {
    padding: 0 10px;
    box-sizing: border-box;
    .title1 {
      font-size: 22px !important;
    }
    .title {
      font-size: 20px !important;
    }
    .login-content-box {
      width: 100% !important;
      background-color: #ffffff;
      border-radius: 30px;
      margin: 40px 0 !important;
      padding-bottom: 30px;
    }
  }
}
// PC端
.login-box {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: repeating-linear-gradient(0deg, #79bbff, #409eff);

  .top-bg-box {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 460px;
    background: url("../static/login/bg1.png") no-repeat;
    background-size: 100% 100%;
  }

  .container-box {
    width: 100vw;
    top: 15vh;
    position: absolute;
    z-index: 9999;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    z-index: 99;

    .title1 {
      font-size: 30px;
      font-weight: 700;
      color: #ffffff;
      display: flex;
      justify-content: center;
      text-align: center;
      margin-bottom: 10px;
    }
    .title {
      font-size: 32px;
      font-weight: 700;
      color: #ffffff;
      display: flex;
      justify-content: center;
    }

    .login-content-box {
      width: 690px;
      background-color: #ffffff;
      border-radius: 30px;
      margin: 40px auto;
      padding-bottom: 30px;

      .tabs-box {
        width: 100%;
        display: flex;

        .tabs-box_item {
          width: 50%;
          text-align: center;
          font-size: 16px;
          color: #666666;
          padding: 15px 0;
          cursor: pointer;
          border-bottom: 2px solid #eeeeee;

          &.active {
            color: #409eff;
            border-bottom: 2px solid #409eff;
          }
        }
      }

      .login-item-box {
        display: flex;
        align-items: center;
      }

      .account {
        margin-top: 50px;
      }

      .password {
        margin-top: 30px;
      }

      .btn-box {
        margin-top: 30px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
      }

      .check-testify-box {
        text-align: right;

        .check-testify-box_item {
          display: inline-block;
          box-sizing: border-box;
          color: #409eff;
          cursor: pointer;
          border-bottom: 1px solid #ffffff;

          &:hover {
            color: #66b1ff;
            border-bottom: 1px solid #66b1ff;
          }
        }
      }
    }
  }

  .bottom-bg-box {
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 93px;
    background: url("../static/login/bg2.png") no-repeat;
    background-size: 100% 100%;
  }
}
</style>
