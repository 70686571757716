<template>
  <el-dialog title="人脸识别" width="600px" :visible.sync="showDialog" center :show-close="false" :close-on-click-modal="false" :modal-append-to-body="false">
    <div class="content-box" v-loading="ocrLoading" element-loading-text="识别中...">
      <div class="close-box" @click="handClose">
        <i class="el-icon-close"></i>
      </div>
      <div class="video-image-box">
        <video class="video-image-box_item" id="videoCamera" autoplay v-show="!imgSrc"></video>
        <img class="video-image-box_item" id="save_img" :src="imgSrc" alt="" v-show="imgSrc" />
      </div>
      <canvas style="display: none" id="canvasCamera" :width="videoWidth" :height="videoHeight"></canvas>
      <div class="btn-box">
        <!-- 点击拍照和保存按钮 -->
        <el-button type="primary" class="save-camera-btn" @click="uploadPicture">拍照上传识别</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import apiConfig from "../api/config.js";
import { commonUpload, userOcr } from '../api/api-list.js'
import { Notification } from 'element-ui'
export default {
  data() {
    return {
      showDialog: false,
      videoWidth: 300,
      videoHeight: 200,
      cameraList: [],
      currentCamera: "",
      imgSrc: "",
      action: "",
      ocrLoading: false,
      type: '',
    };
  },
  mounted() {
    this.action = apiConfig.host + '/api/common/upload'
  },
  methods: {
    // 手动关闭弹窗
    handClose() {
      this.$emit('handClose')
      this.closeDialog()
    },
    // 考试结束，暂停获取配置
    stopGetCommonConfig() {
      this.stopOrc = true
    },
    // 识别成功
    ocrSuccess() {
      this.closeDialog()
      this.$emit('ocrSuccess')
    },
    // 上传文件
    async commonUploadFun(file) {
      const formData = new FormData()
      formData.append('file', file)
      this.ocrLoading = true
      let res = await commonUpload(formData)
      console.log('res', res)
      if (res.code == 1) {
        let ocrRes = await userOcr({ image: res.data.url })
        this.ocrLoading = false
        if (ocrRes.code == 1) {
          if (ocrRes.data.is_pass) {
            Notification({
              title: '成功',
              message: '识别成功',
              type: 'success',
              duration: 3000
            });
            this.ocrSuccess()
          } else {
            Notification({
              title: '失败',
              message: '识别失败，请重新拍照上传',
              type: 'error',
              duration: 3000
            });
            this.imgSrc = ''
            // this.ocrSuccess()
          }
        } else {
          // this.ocrSuccess()
        }
        console.log(ocrRes)
      } else {
        this.ocrLoading = false
      }
    },
    // 上传图片
    async uploadPicture() {
      this.thisContext.drawImage(this.thisVideo, 0, 0, this.videoWidth, this.videoHeight);
      // 获取图片base64链接;
      this.imgSrc = this.thisCanvas.toDataURL("image/png", 0.7);
      const time = (new Date()).valueOf()
      const conversions = this.base64ToFile(this.imgSrc, time)
      this.commonUploadFun(conversions)
    },
    // 显示弹窗
    async showOcrModal() {
      let res = await this.getDevices()
      console.log('res111', res);
      if (apiConfig.test) {
        this.ocrSuccess()
      }else if (res.isSuccess) {
        this.showDialog = true
        this.cameraList = res.cameraList;
        setTimeout(() => {
          this.currentCamera = this.cameraList[0].id;
          this.thisCanvas = document.getElementById("canvasCamera");
          this.thisContext = this.thisCanvas.getContext("2d");
          this.thisVideo = document.getElementById("videoCamera");
          this.enableCamera(this.currentCamera);
        }, 200)
      } else if (!res.isSuccess) {
        this.$emit('showOcrModalError', res.text)
      }
    },
    // 获取摄像头列表
    async getDevices() {
      return new Promise((resolve, reject) => {
        let params = {
          isSuccess: false
        }
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
          params.text = '没有开启摄像头权限或浏览器版本不兼容'
          resolve(params);
        } else {
          // 在 navigator.mediaDevices.enumerateDevices() 方法前加上以下代码：
          // navigator.mediaDevices.getUserMedia({audio: true, video: true});
          // 访问会出现询问弹窗
          navigator.mediaDevices.getUserMedia({ audio: true, video: true })
          window.navigator.mediaDevices.enumerateDevices().then(devices => {
            console.log('devices', devices);
            let cameraList = [];
            devices.forEach((device, index) => {
              console.log('device', device)
              // 获取摄像头外设 videoinput
              if (device.kind && device.kind === "videoinput") {
                if (device.deviceId) {
                  cameraList.push({
                    id: device.deviceId,
                    label: device.label
                  });
                  params.isSuccess = true
                }
              }
            });
            if (cameraList.length == 0) {
              params.text = '没有找到摄像头'
            }
            params.cameraList = cameraList
            resolve(params);
          }).catch(err => {
            console.error('err111', err);
            params.text = JSON.stringify(err)
            resolve(params);
          });
        }
      });
    },
    // 开启摄像头
    enableCamera(deviceId) {
      this.getUserMedia(
        deviceId,
        stream => {
          if ("srcObject" in this.thisVideo) {
            this.thisVideo.srcObject = stream;
          } else {
            // 避免在新的浏览器中使用它，新浏览器正在被弃用。没有开启摄像头权限或浏览器版本不兼容
            this.thisVideo.src = window.URL.createObjectURL(stream);
          }
          this.thisVideo.onloadedmetadata = e => {
            this.thisVideo.play();
          };
        },
        error => {
          console.log(`访问用户媒体设备失败${error.name}, ${error.message}, ${error}`);
          Notification({
            title: '警告',
            message: '摄像头打开失败，请检查摄像头并点击浏览器地址栏右侧的摄像头标志进行开启设置',
            type: 'warning'
          });
        }
      );
    },
    /**
     * @desc 获取摄像头权限 getUserMedia
     * deviceId	getDevices()函数获取的设备id
     * success  调用成功的回调函数
     * error	调用失败的回调函数
     */
    getUserMedia(deviceId, success, error) {
      const constraints = {
        audio: false,
        video: {
          width: this.videoWidth,
          height: this.videoHeight,
          transform: "scaleX(-1)",
          deviceId: deviceId
        }
      };
      if (navigator.mediaDevices.getUserMedia) {
        //最新的标准API
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(success)
          .catch(error);
      } else if (navigator.webkitGetUserMedia) {
        //webkit核心浏览器
        navigator.webkitGetUserMedia(constraints, success, error);
      } else if (navigator.mozGetUserMedia) {
        //firfox浏览器
        navigator.mozGetUserMedia(constraints, success, error);
      } else if (navigator.getUserMedia) {
        //旧版API
        navigator.getUserMedia(constraints, success, error);
      }
    },
    // base64图片转file的方法（base64图片, 设置生成file的文件名）
    base64ToFile(base64, fileName) {
      // 将base64按照 , 进行分割 将前缀  与后续内容分隔开
      const data = base64.split(',')
      // 利用正则表达式 从前缀中获取图片的类型信息（image/png、image/jpeg、image/webp等）
      const type = data[0].match(/:(.*?);/)[1]
      // 从图片的类型信息中 获取具体的文件格式后缀（png、jpeg、webp）
      const suffix = type.split('/')[1]
      // 使用atob()对base64数据进行解码  结果是一个文件数据流 以字符串的格式输出
      const bstr = window.atob(data[1])
      // 获取解码结果字符串的长度
      let n = bstr.length
      // 根据解码结果字符串的长度创建一个等长的整形数字数组
      // 但在创建时 所有元素初始值都为 0
      const u8arr = new Uint8Array(n)
      // 将整形数组的每个元素填充为解码结果字符串对应位置字符的UTF-16 编码单元
      while (n--) {
        // charCodeAt()：获取给定索引处字符对应的 UTF-16 代码单元
        u8arr[n] = bstr.charCodeAt(n)
      }
      // 利用构造函数创建File文件对象
      // new File(bits, name, options)
      const file = new File([u8arr], `${fileName}.${suffix}`, {
        type: type
      })
      // 将File文件对象返回给方法的调用者
      return file
    },
    // 关闭弹窗
    closeDialog() {
      if (this.thisVideo && this.thisVideo.srcObject) {
        this.thisVideo.srcObject.getTracks()[0].stop();
      }
      this.showDialog = false
    }
  },
}
</script>
<style scoped lang='scss'>
.content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .close-box {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 18px;
    cursor: pointer;
  }

  .video-image-box {
    width: 100%;
    height: 100%;

    .video-image-box_item {
      width: 100%;
      height: 100%;
    }
  }
}

.btn-box {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>