<template>
  <div class="exam-list-box">
    <el-container style="height: 100%; border: 1px solid #eee" v-loading="loading" v-if="examInfo.id">
      <el-header class="exam-list-header">
        <span>{{ userInfo.nickname }}</span>
      </el-header>
      <el-container class="item-container-box">
        <el-main class="main-box">
          <div class="main-box_content">
            <div class="main-tile">{{ examInfo.subject.name }}</div>
            <!-- <div class="main-paper-name">
              <div class="lable">试卷名称：</div>{{ examInfo.paper.name }}
            </div>
            <div class="main-paper-name">
              <div class="lable">考试批次：</div>{{ examInfo.exam_lot.name }}
            </div> -->
            <div class="main-paper-name">
              <div class="lable">姓名：</div>{{ examInfo.name }}
            </div>
            <div class="main-paper-name">
              <div class="lable">身份证号：</div>{{ examInfo.id_card_no }}
            </div>
            <div class="main-paper-name">
              <div class="lable">培训机构：</div>{{ examInfo.train_admin.nickname }}
            </div>
            <div class="main-paper-name">
              <div class="lable">培训期次：</div>{{ examInfo.train_lot.name }}
            </div>
            <div class="main-paper-name">
              <div class="lable">考试机构：</div>{{ examInfo.exam_admin.nickname }}
            </div>
            <!-- <div class="main-paper-name">
              <div class="lable">考试期次：</div>{{ examInfo.exam_lot.name }}
            </div> -->
            <!-- <div class="main-describe">考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求考试要求</div> -->
          </div>
          <div>
            <el-button style="width: 200px; height: 50px; font-size: 18px;" type="primary"
              @click="gotoExam">进入考试</el-button>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <CameraModalVue ref="cameraModalVue" @showOcrModalError="showOcrModalError" @ocrSuccess="ocrSuccess"></CameraModalVue>
  </div>
</template>

<script>
import CameraModalVue from '@/components/CameraModal.vue'
import { examGetList, commonConfig, examGetQuestionList } from '@/api/api-list';
import apiConfig from "../api/config.js";
export default {
  components: {
    CameraModalVue
  },
  data() {
    return {
      examInfo: {},
      userInfo: {},
      loading: false
    }
  },
  mounted() {
    let userInfo = localStorage.getItem('userInfo')
    this.userInfo = JSON.parse(userInfo)
    this.getExamList()
  },
  methods: {
    // 获取考试列表
    async getExamList() {
      this.loading = true
      let res = await examGetList()
      this.loading = false
      if (res.code == 1) {
        this.examInfo = res.data[0]
        console.log(this.examInfo)
      } else {
        setTimeout(() => {
          localStorage.removeItem('userInfo')
          localStorage.removeItem('token')
          this.$router.push('/')
        }, 1000)
      }
    },
    // 进入考试
    async gotoExam() {
      this.loading = true
      let qRes = await examGetQuestionList({ 'number': this.examInfo.number })
      this.loading = false
      if (qRes.code == 1) {
        let res = await commonConfig()
        let configInfo = res.data
        if (configInfo.ocr.exam_before_ocr == 1 && !apiConfig.test) {
          this.$refs.cameraModalVue.showOcrModal()
        } else {
          this.ocrSuccess()
        }
      }

    },
    // 识别成功
    ocrSuccess() {
      this.$router.push({
        path: '/exam',
        query: {
          paper_id: this.examInfo.paper_id,
          number: this.examInfo.number
        }
      })
    },

    showOcrModalError(text) {
      this.$notify({
        title: '警告',
        message: text,
        type: 'warning'
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.exam-list-box {
  width: 100vw;
  min-height: 100vh;

  .el-container {
    border: none !important;
    height: calc(100vh - 60px);

    .exam-list-header {
      background-color: #409eff;
      color: #ffffff;
      line-height: 60px;
      font-size: 18px;
      text-align: right;
      padding: 0 50px;
    }

    .el-aside {
      color: #333;
    }

    .main-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 100px;

      .main-box_content {
        text-align: center;

        .main-tile {
          text-align: center;
          font-size: 30px;
          font-weight: bold;
          margin: 60px 0;
        }

        .main-paper-name {
          font-size: 20px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          .lable {
            text-align: right;
            width: 150px;
          }
        }

        .main-describe {
          text-indent: calc(2em + 5px);
          letter-spacing: 2px;
        }
      }
    }
  }
}
</style>

